import _isEmpty from "is-empty";
import _isWhitespace from "is-whitespace";
var exports = {};
var isEmpty = _isEmpty;
var isWhitespace = _isWhitespace;

function isString(object) {
  return typeof object === "string";
}

exports = function (object) {
  return isString(object) && object.length ? isWhitespace(object) : isEmpty(object);
};

export default exports;